.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  gap: 1rem;
  margin: 2rem;
}
.pagination .active a {
  color: #E61E28;
}
.pagination .pageNum {
  cursor: pointer;
  padding: 0.2rem 0.4rem;
  border-radius: 0.375rem;
}
.pagination .pageNum:hover {
  color: #E61E28;
  padding: 0.15rem 0.4rem;
  cursor: pointer;
  border-radius: 0.375rem;
}
.pagination .active {
  padding: 0.025rem 0.2rem;
  cursor: pointer;
  border-radius: 0.375rem;
}
