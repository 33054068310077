::-webkit-scrollbar-thumb {
  background-color: #b5b5b8;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-track {
  width: 0.5rem;
  height: 0.5rem;
}
